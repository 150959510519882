<div #addattendee>

<form [formGroup]="form"  class="relate-form">
  <div>
    <h3>Register for: {{ event?.name}} </h3> (Scroll down for more fields)
    
    <mat-form-field  appearance="outline" >
      <mat-label>First Name</mat-label>
      <input matInput 
        formControlName="first"
        id="first"
        required
      />

    </mat-form-field>


    <mat-form-field  appearance="outline" >
      <mat-label>Last Name</mat-label>
      <input matInput 
        formControlName="last"
        id="last"
        required
      />

    </mat-form-field>

    <br />
     

    <mat-form-field  appearance="outline" >
      <mat-label>Email</mat-label>
      <input matInput 
        formControlName="email"
        id="email"
        required
      />

    </mat-form-field>

    <br />

    <mat-form-field  appearance="outline" >
      <mat-label>City</mat-label>
      <input matInput 
        formControlName="city"
        id="city"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>State</mat-label>
      <input matInput 
        formControlName="state"
        id="state"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Zip</mat-label>
      <input matInput 
        formControlName="zip"
        id="zip"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Country</mat-label>
      <input matInput 
        formControlName="country"
        id="country"
      />

    </mat-form-field>
    <br />
    <mat-form-field  appearance="outline" >
      <mat-label>Phone</mat-label>
      <input matInput 
        formControlName="phone"
        id="phone"
      />

    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-label>Gender</mat-label>
      <mat-select matNativeControl   formControlName="gender">
        <mat-option *ngFor="let nnI of genderTypes" [value]="nnI">
          {{ nnI }}
        </mat-option>
      </mat-select>


    </mat-form-field>
    <br />
    <mat-label>Accept Body of 9, Inc. Terms and Conditions</mat-label> &nbsp; <mat-checkbox (change)="selecttandc()" [checked]="form.value.tandc"></mat-checkbox>&nbsp;
    <a href="https://bodyof9.com/conscious-life-expo-terms-and-conditions-of-participation/" target="_blank">View T & Cs</a>

  </div>

</form>



 <span *ngIf="!submitted">
  <span *ngIf="form.value.email">
    <button mat-raised-button type="button" [disabled]="disableBtn" (click)="submitNow()" class="btn btn-save mat-submit-button" color="accent">Ready Now</button> 
  <button mat-raised-button type="button" [disabled]="disableBtn" (click)="reserveATime()" matTooltip="Reserve a Time" color="primary" class="btn btn-save mat-submit-button" >Reserve a Time Later</button>
<div *ngIf="slots" >
  <span *ngFor="let item of slots; index as idx" class="slots">
    <b>{{item.eventDate}}</b>
    <div *ngFor="let appt of item.appointments; index as idxA">
     <span *ngIf="!appt.attendeeKey" >  <button (click)="reserveTime(item.eventDate, appt.appointmentTime)" [ngClass]="appt.attendeeKey ? 'taken': 'available'"> {{appt.appointmentTime }}</button> </span>
    </div>
    
    </span>
    </div>
  </span>
  <br />
  <br />

 
  <button mat-raised-button  (click)="resetForm()"  class="btn btn-save mat-submit-button" color="warn">Reset Form</button>

</span>
<span *ngIf="submitted">
  <a href="https://www.canva.com/design/DAGYfMPhjMQ/7wSXJ8opaftGozSqyDUmpg/watch" target="_blank"> <h2>Show me what to expect (click here)</h2> </a> 
</span>
</div>