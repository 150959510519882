import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, AfterViewChecked, ElementRef } from '@angular/core';

import { ArangoService } from 'src/services/arangoservice/arango.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { Person, personFromAttendee,getGender} from '../../../../../bo9-shared/models/person/person.model';
import { Difference} from '../../../../../bo9-shared/models/person/differences.model';
import {  differencesForSource, getDifferences, sameDifference} from '../../../../../bo9-shared/models/person/differencesCode.model';
import { validationMessages } from 'src/helpers/form.helper';
import { BO9Contact, canContact, contactFullName } from '../../../../../bo9-shared/models/contact/contact.model';
import {  Invite } from '../../../../../bo9-shared/models/person/invite.model';
import { EventDBService } from 'src/services/events/eventdb.service';
import { Event, EventAttendee, EventParams,  Set_Transaction } from '../../../../../bo9-shared/models/event/event.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';

import { SearchService } from 'src/services/search/search.service';
import { ActiveCampaignService } from 'src/services/activecampaign/activecampaign.service';
import { addNaturalNumber, fixIDBy, makeMTNaturalNumber, NaturalNumber, NaturalNumberTypes, stringToNaturalNumber } from '../../../../../bo9-shared/models/naturalNumber/naturalNumber.model';
import { dateFromYYYYMMDD, DateToYYMMDD, DateToYYMMDDhhmmss } from '../../../../../bo9-shared/helpers/time.helper';
import { EventEmitterService } from 'src/services/events/events.service';
import { ActivityService } from 'src/services/activity/activity.service';
import { Activity } from '../../../../../bo9-shared/models/activity/activity.model';
import { createUUID } from '../../../../../bo9-shared/uuid.service';
import { updateMeta } from '../../../../../bo9-shared/models/BO9_base/meta.model';
import { AuthService } from 'src/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { makeDefaultPearl, ModuleDetail } from '../../../../../bo9-shared/models/product/product.model';
import { addPurchase, createPurchaseFromSubscription } from '../../../../../bo9-shared/models/purchase/purchase.model';
import { PearlService } from 'src/services/pearl/pearl.service';
import { ActCamFields } from '../../../../../bo9-shared/models/mailCampaign/activeCampaign.model';

import { levelFromSysRole, userIsAdmin, userIsMaster } from '../../../../../bo9-shared/models/person/BO9rolesmethods.model';
import { DeviceDetectorService} from 'ngx-device-detector'
import { Slot, updateSlots, updateSlotsAttendee } from '../../../../../bo9-shared/models/slot/slot.model';

@Component({
    selector: 'app-attendees',
    templateUrl: './attendees.component.html',
    styleUrls: ['./attendees.component.scss'],
    standalone: false
})
export class AttendeesComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit{
  @Input() event: Event;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('table', {read: ElementRef}) table: ElementRef;

  @Output() notifyParent: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() mergePerson: EventEmitter<{orig: Person, new: Person, difference: Difference[]}> = 
    new EventEmitter<{orig: Person, new: Person, difference: Difference[]}>();
  messagesLoading$: Observable<boolean>;
  events$: Observable<Person[]>;

  pageNum: number;
  totalPages: number;
  pageSize: number;
  attendees: EventAttendee[] =[];

  fromName = 'Not Set';

 
  maxSize: number = 7;
  directionLinks: boolean = true;
  onlyShowToday: boolean = true;
  autoHide: boolean = false;
  responsive: boolean = true;

  searchString: string;

  currentP$: Observable<Person>;
  currentPerson: Person;
  people: Person;
  fieldValues: number;
  totalEvents: number;
  relatingPersonName: string;
  eventTag: string = '';
  operation: string = 'none';
  currentSource: EventAttendee;
  nnKeys: string[];
  fromF$:Observable<Person>;
  attendee: EventAttendee;

  validationMessages = validationMessages;

  Invites$: Observable<Invite[]>;
  PearlModuleTypes$: Observable<string[]>;
  loading = false;
  tagList =[];
  email: string;
  idList = [];
  user: Person;
  disableBtn = false;

  hours: number[] = [];
  paid: string[] = [];
  attended: boolean[] = [];
  pageTitle = "Attendees"; 
  emailList$: Observable<number[]> = of([]);
  theEmail = "";
  theEmailIndex = 0;

  emailMap: Map<string, { first: string, last: string, count: number[]; }>;

  public displayedColumns = ['controls', 'first', 'last', 'email', 'attended', 'assBy', 'ass', 'city', 'dnc', 'time','paid', 'delete'];
 // public displayedColumns = ['controls', 'first', 'last', 'email', 'guild', 'attended', 'idBy', 'ass', 'city', 'dnc', 'paid', 'delete'];

  public mobileColumns =   ['edit','who','idBy', 'nn'];
  public dataSource = new MatTableDataSource<EventAttendee>();
  subscriptions: Subscription[] = [];


  colList : string[]=[];
  constructor(
    private eventdbService: EventDBService,
    private activityService: ActivityService,
    private pearlService: PearlService,
    private router: Router,
    private arangoService: ArangoService,
    private auth: AuthService,
    private searchService: SearchService, 
    private activeCampaignService: ActiveCampaignService,
    private route: ActivatedRoute,
    private emitterService: EventEmitterService,
    private eventDBService: EventDBService,
    private deviceService: DeviceDetectorService,
    ) 
  {

    this.onlyShowToday = true;
    this.disableBtn = false;
    this.user = this.auth.getAuthUser();
   
    this.idList = [];
    this.nnKeys = Object.keys(NaturalNumber).map(key => NaturalNumber[key]).filter(value => typeof value === 'string') as string[];
    this.subscriptions.push(this.arangoService.recordVisit({
      page: this.router.url, component: 'AttendeesComponent', 
      visitor: this.user._key,
      help: false
    }).subscribe({ next: () => { } })); 
    this.subscriptions.push(this.arangoService.getIdentifiers()
      .subscribe(async (res) => {
        console.log('get identifers ' + JSON.stringify(res));
        for (const cat of res.content) {
          this.idList.push(cat.Name);
        }
      }
      ));
    this.emailMap = new Map();
    this.subscriptions.push(this.route.params.subscribe( async (params) => {
       
      if (params['id']) {
        console.log(params); // log the entire params object
        console.log(" Params "+params['id']); // log the value of id
        if ( params['id'] === '-1') {
          const result = await firstValueFrom(this.eventDBService.getCurrentEvents());
            if (result && result.content) {
              this.event = result.content[0];
            }
        } else {
         const result = await firstValueFrom(this.eventDBService.getEventByID(params['id'])); // Can only be from wordpress event
        if (result && result.content) {
          this.event = result.content.events[0];

        }
      }
      }
      const res = await firstValueFrom(this.activeCampaignService.getContactTagFieldValues(false))
      this.tagList = this.activeCampaignService.tagAndFields.tags;
      this.operation='none';
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this.refreshNoDups();;
    }))
  }



  isMobile(): boolean {
    return this.deviceService.isMobile()
  }

  isToday(): boolean {
    return( this.onlyShowToday);
 
 }



 
  onPreview(){
    let printContents = '';
    const WindowObject = window.open('','PrintWindow','width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes'
    );
    printContents += `<table>
                     <tr>
                       <th>First Name</th>
                       <th>Last Name</th>
                       <th>Email</th>
                       <th>Guild</td
                       <th>Ass By</th>
                       <th>Ass</th>
                       <th>Id By</th>
                       <th>NN</th>
                       <th>City</th>
                       <th>Paid</th>
                       <th>Notes</th>
                     </tr>`;
    this.attendees.map( (data) => {
         printContents += `<tr>
                       <td>${data.first}</td>
                       <td>${data.last}</td> 
                       <td>${data.email}</td>
                       <td>${data.guild}</td>
                       <td>${data.assBy}</td>
                       <td>${data.ass}</td>
                       <td>${data.idBy}</td>
                       <td>${data.nn}</td>
                       <td>${data.city}</td>
                       <td>${data.paid}</td>
                       <td>___________________________</td>
                       
                     </tr>`});
    const htmlData = `<html><body>${printContents}</body></html>`;
  
    WindowObject.document.writeln(htmlData);
    WindowObject.document.close();
    WindowObject.print();
    WindowObject.focus();
    setTimeout(() => {
      WindowObject.close();
    }, 0.5);
  }

  ngAfterViewInit(): void {

    if (userIsAdmin(this.user) || userIsMaster(this.user)) {
      this.colList = ['controls', 'first', 'last', 'email', 'attended', 'assBy', 'ass', 'idBy','nn', 'dnc', 'time','paid', 'erase'];
      if (this.isMobile()) {
        this.colList = ['edit', 'time','who','idBy', 'nn', 'dnc', 'paid'];
      }  
    } else {
      if (this.isMobile()) {
        this.colList = this.mobileColumns;
      }  else {
        this.colList =  this.displayedColumns
      }
    }
    this.onlyShowToday = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  isTraining(e: Event): boolean {
    if (this.event && this.event.categories) {
      for (const c of this.event.categories) {
        if (c.includes('Training')) {
          return true
        }
      }

    }

    return false

  }

 
  
 byAssSelected($event: any, element: any) {
    console.log(`by selected `+JSON.stringify($event.value));
    if (!element.person) {
      alert("Can't update person not saved to DB ");
    } else {
      const p:Person = element.person;

        p.naturalNumbers.asserted.setBy =  {
          name: $event.value.join(","),
          uuid: '',
          comment: ''
        };

      this.subscriptions.push( this.arangoService.updatePerson(p)
      .subscribe( (res) => {
        
        }))

    }

 }
  
  onAll() {
    this.onlyShowToday = false;
    this.refreshNoDups();
  }

  onOnly() {
    this.onlyShowToday = true;
    this.refreshNoDups();
  }
  
 byIdSelected($event: any, element: any) {
  console.log(`by selected `+JSON.stringify($event.value));
  if (!element.person) {
    alert("Can't update person not saved to DB ");
  } else {
    const p:Person = element.person;
    
    
      p.naturalNumbers.identified.setBy =  {
        name: $event.value.join(","),
        uuid: '',
        comment: ''
      };
   

    this.subscriptions.push( this.arangoService.updatePerson(p)
    .subscribe( (res) => {
      
      }))

  }

}
  
 isAdmin(): boolean {
  return(userIsAdmin(this.user));
 }
  
 


  selectDNC($event: any) {
    this.disableBtn = true;
    console.log(`select DNC `+JSON.stringify($event));
    $event.person.contact.DNC = ! $event.person.contact.DNC;
    this.subscriptions.push( this.arangoService.updatePerson($event.person)
    .subscribe( (res) => {
      this.disableBtn = false;
      }))
  }

  nnAsserted($event: any, element: any, index: number) {
    this.setNN($event, element, index, true);
  }
  nnSelected($event: any, element: any, index: number) {
    this.setNN($event, element, index, false);
  }

  setNN($event: any, element: any, index: number, isAsserted: boolean) {
    
    const p: Person = element.person;
    if (!element.person) {
      alert("Can't update person not saved to DB ");
    } else if ( !(p.naturalNumbers.identified.setBy.name || p.naturalNumbers.asserted.setBy.name)) {
      alert("Can't update person without selecting identifier");
    } else {
      console.log(`NN selected ${element.person._key} `+JSON.stringify($event.value));
      this.disableBtn = true;

      if (isAsserted) {
        p.naturalNumbers.asserted = addNaturalNumber($event.value,this.event._key + ':' + this.event.name, 
            p.naturalNumbers.asserted.setBy.name, DateToYYMMDDhhmmss(),'Event', "Asserted","");
      } else {
        p.naturalNumbers.identified = addNaturalNumber($event.value,this.event._key + ':' + this.event.name, 
            p.naturalNumbers.identified.setBy.name, DateToYYMMDDhhmmss(),'Event', "Identified","");
      }


      p.latestEvent = { // Set this so the update knows that its an event based update
        event_id: this.event._key,
        event_date: this.event.startDate
      }
      this.checkAttendeeInActCamp(p)
        .then(async () => {
          
          if (!p.primaryEmail) {
            
            p.primaryEmail = element.person.primaryEmail;
          }
        
        
          if (!isAsserted) {

            p.purchases = addPurchase(p.purchases, createPurchaseFromSubscription(p._key, p.primaryEmail, [makeDefaultPearl()]));
            console.log(`Create Pearl Purchase for  ${element.person.primaryEmail} `);
           // const update = await firstValueFrom(this.arangoService.updatePerson(p)); // moved after this block
            console.log(`Create Pearl subscription for  ${element.person.primaryEmail} `);
            this.emitterService.emitter.emit('Message', `Create Pearl subscription for  ${element.person.primaryEmail} `);
            const r = await firstValueFrom(this.pearlService.subscribe(p.primaryEmail, 'daily'));
                  
            if (canContact(p.contact)) {
              const resSync = await  firstValueFrom(this.activeCampaignService.syncContact(p))
              this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated and ActiveCampaign Updated');
            } else {
              this.emitterService.emitter.emit('Message',p.primaryEmail + ' DB Updated but not ActiveCampaign Updated (DNC)');
            }
          }
          const update = await firstValueFrom(this.arangoService.updatePerson(p));
          const epresult: any = await firstValueFrom(this.arangoService.getEventParamByEventId(this.event._key));
          console.log(`EP result  `+JSON.stringify(epresult));
          if (epresult && epresult.content && epresult.content.length > 0) {
            const ep: EventParams = epresult.content[0];
            if (!ep.usedDate) {
              // set both deal and eventparam
              const setUed = await firstValueFrom(this.activeCampaignService.useprepay(ep.dealId,ep.id))
            }
          }

          let actualEventService; 
            let spent = 0; // not attended is 0


            spent = this.hours[index]?this.hours[index]:0

            if ((!this.event.source) || this.event.source === 'wordpress') {  // Update event based on event source
              actualEventService = this.eventdbService;
            } else {
              actualEventService = this.arangoService;
            }
            this.attended[index] = true;

            const setatt = await firstValueFrom( actualEventService.setEventAttendeeAttended(p._key, p._key, this.event._key, this.event.startDate, 1, spent))
   
      
            this.emitterService.emitter.emit('Message','Set Event Attended for ' + JSON.stringify( contactFullName(p.contact)));
      
          const act: Activity = {
            _key :createUUID(),
            from : p._key,
            to : p._key,
            kind : 'Identified',
            note : this.event._key + ':' +this.event.name,
            date : DateToYYMMDDhhmmss(),
            by : p.naturalNumbers.identified.setBy.name,
            NN: stringToNaturalNumber($event.value),
            meta : updateMeta()
          }
  
          const active = await firstValueFrom(this.activityService.addActivity(act, 'person'))
          
          this.disableBtn = false;

         
      });

    }
  }



  ngOnChanges() {
      this.refreshNoDups();; 
  }

  hasDups(): boolean {
    for (let [key, value] of this.emailMap) {
      console.log(key, value)
      if (value.count.length > 1) {
        return true
      }
    }

    return false
  }

  async changePaid(att: EventAttendee, ind: number) {
    console.log("att updated as paid " + JSON.stringify(ind))
    att.paid = this.paid[ind] ? this.paid[ind] : "0.00";
    if (!att.paid.includes(".")) {
      att.paid = att.paid + ".00";
    }
    att.paid = att.paid.replace("$", '');
     att.transaction_details = Set_Transaction("Updated at Event",att.paid, att.transaction_details);
    const res = await firstValueFrom(this.eventDBService.updateEventAttendee(att));
    alert("Updated Paid Amount "+att.paid);
  }

  refresh(): Promise<EventAttendee[]> {
    return new Promise(async (resolve, reject) => {
      if (this.user.preferences) {
        this.pageSize = this.user.preferences.rowsPerPage ? +this.user.preferences.rowsPerPage : 15;
      }
      if (this.event) {

        this.loading = true;
        this.dataSource.data = [];
        this.attendees = [];
        // this.eventTag = getTagId('EE'+this.event._key, this.tagList);
        let actualEventService;
        if (this.event.source !== 'calendly') {
          actualEventService = this.eventdbService;
        } else {
          actualEventService = this.arangoService;
        }
        const result:any = await firstValueFrom(actualEventService.getEventAttendees(this.event._key));

        let e;

        if (this.event.source !== 'calendly') {
          e = await this.makeEventAttendee(result.content);
        } else {
          e = await this.makeEventAttendee(result.content.attendees);
        }

        this.loading = false;
        this.dataSource.data = e;

        resolve(e);
       
      }
    })
  }


  clickAttended($event: any, person: EventAttendee, index: number) {

    let actualEventService; 
    this.disableBtn = true;
    if (person) {
      let spent = 0; // not attended is 0
      this.attended[index] = $event.checked;
  
      if(this.attended[index]) {
        spent = this.hours[index]?this.hours[index]:0
      }
      if ((!this.event.source) || this.event.source === 'wordpress') {  // Update event based on event source
        actualEventService = this.eventdbService;
      } else {
        actualEventService = this.arangoService;
      }
      this.subscriptions.push( actualEventService.setEventAttendeeAttended(person._key, person.person._key, this.event._key, this.event.startDate,  this.attended[index], spent)
      .subscribe(  (res) => {
 
        this.disableBtn = false;
        this.emitterService.emitter.emit('Message','Set Event Attended for ' + JSON.stringify( contactFullName( person.person.contact)));

      }))
    }

  }


  ngOnInit() {
    this.operation='none';
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onRefreshClick() {
    this.refreshNoDups();
  }


  onCloseClick() {
    this.operation='none';
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      if (!this.isMobile()) this.refreshNoDups();
    }
  }

  onClickEdit(p:EventAttendee) {
    this.operation='editattendee';
    this.currentSource= p;
    this.attendee = p;

  }

  onClickSubscriptions(p:EventAttendee) {
    this.operation='subscriptions';
    this.currentSource= p;
    this.attendee = p;
    this.fromF$ = of(p.person);

  }

  onClickRelate(p:EventAttendee) {
    this.operation='relations';
    this.currentSource= p;
    this.fromF$ = of(p.person);
    this.email= p.email;
    this.fromName = p.first + ' ' + p.last;
  }

  onClickDelete(p:EventAttendee) {
    if (confirm('Are you sure you wish to delete '+ p.first + ' ' + p.last + ' as an attendee?')) {
      this.disableBtn = true;
      if (this.event.source !== 'calendly') {
        this.subscriptions.push( this.eventdbService.deleteAttendee(p._key)
        .subscribe(  (result) => {
          console.log('Deleted WP result  is: ' + JSON.stringify(result.content));
          alert(`Manually remove ${p.email} from the Deal for this event!`);
          this.emitterService.emitter.emit('Message','Attendee ' + p.email+ ' deleted');
         
          if (this.user.preferences && this.user.preferences.autoRefresh) {
            this.refreshNoDups();;
          }
          
        }))
      } else {
        this.subscriptions.push( this.arangoService.deleteAttendee(p._key)
        .subscribe(  (result) => {
          console.log('Deleted Cal result  is: ' + JSON.stringify(result.content));
          alert(`Manually remove ${p.email} from the Deal for this event!`);
          this.emitterService.emitter.emit('Message','Attendee ' + p.email+ ' deleted ');
                   
          if (this.user.preferences && this.user.preferences.autoRefresh) {
            this.refreshNoDups();;
          }
          
        }))
      }
      this.subscriptions.push(this.arangoService.getEmptySlots(p.event_id.replace("EE",""))
        .subscribe((result) => { 
          const newSlots = updateSlotsAttendee(p.start_date,p._key,result.content);
          if (newSlots) {
            const slotres = this.subscriptions.push(this.arangoService.putSlots(newSlots)
              .subscribe((res) => {
                
              }))
          }
        }))

      this.disableBtn = false;
    }
   }

   onClickErase(p:EventAttendee) {
    if (confirm('Are you sure you wish to Erase '+ p.first + ' ' + p.last + ' from the system?')) {
      if (confirm('Are you REALLY sure you wish to Erase '+ p.first + ' ' + p.last + ' from the system?')) {
        this.disableBtn = true;
        if (this.event.source !== 'calendly') {
          this.subscriptions.push( this.eventdbService.deleteAttendee(p._key)
          .subscribe( async (result) => {
            console.log('Deleted cal result  is: ' + JSON.stringify(result.content));
            this.emitterService.emitter.emit('Message','Attendee ' + p.email+ '  removed from event');
            const d = await firstValueFrom(this.arangoService.deletePerson(p._key));
            this.emitterService.emitter.emit('Message','Attendee ' + p.email+ '  removed from backend');
            const actId: ActCamFields = await firstValueFrom(this.activeCampaignService.getContactByEmail(p.email));
            if (actId ) {
              const da = await firstValueFrom(this.activeCampaignService.deleteContact(actId._key));
              this.emitterService.emitter.emit('Message','Attendee ' + p.email+ '  removed from activecampaign');
            }
            if (this.user.preferences && this.user.preferences.autoRefresh) {
              this.refreshNoDups();;
            }
            
          }))
      }}
      this.disableBtn = false;
    }
   }

  onClickAddAttendee() {
    this.attendee = null;
    this.operation="editattendee";
  }


  onClickView(p:EventAttendee) {
    console.log('View Attendee of Event '+ JSON.stringify(p.person));
    this.mergePerson.emit({orig: p.person, new: null, difference: null});
  }

  onClickMerge(p:EventAttendee) {
    console.log('Merge Attendee of Event '+ JSON.stringify(p));
    this.mergePerson.emit({orig: p.person, new: p.newPerson, difference: p.difference});
  }

  // / {"_key":230,"first":"Mandisa","last":"Snodey","email":"LaShon.snodey@gmail.com","street1":"","street2":"","city":"","state":"","zip":"","country":""}
  onClickSave(p:any) {
    console.log('Save Attendee  '+ JSON.stringify(p));
    this.disableBtn = true;
    this.loading = true;
    const newP =  personFromAttendee(p);
    this.subscriptions.push( this.arangoService.updatePerson(newP)
    .subscribe( (result) =>{
      console.log('Update person result  is: ' + JSON.stringify(result.content));
      this.emitterService.emitter.emit('Message','Attendee ' + contactFullName(newP.contact) + ' saved');
      this.loading = false;
      if (this.user.preferences && this.user.preferences.autoRefresh) {
        this.refreshNoDups();;
      }
      this.disableBtn = false;
    }))

  }

  onClickAdd(p:Event) {
    console.log('Add attendee '+ JSON.stringify(p.name));


  }

  async refreshNoDups() {
    this.emailMap = new Map();
    const res = await this.refresh();
    /*
    if (this.hasDups()) {
      alert("Multiple attendees share the same email, you will be taken to a form that requires you to chose only 1 person to retain that email, the others will be cleared");
      for (let [key, value] of this.emailMap) {
        if (value.count.length > 1) {
          this.operation = 'singleemail';
          this.emailList$ = of(value.count);
          this.theEmail = key
        }
      }
    } else {
    */
      for (let i = 0; i < this.attendees.length; i++)  {
        if (!this.attendees[i].person) {
          const person = personFromAttendee(this.attendees[i]);
          console.log("Add new person "+ JSON.stringify(person));
          const res = await firstValueFrom(this.arangoService.updatePerson(person));  // Adding 
          console.log('Added result  is: ' + JSON.stringify(res.content));
          this.emitterService.emitter.emit('Message', 'Auto Add Person to DB: ' + contactFullName(person.contact));
        }
      }
      this.dataSource.data = this.attendees;
      this.disableBtn = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loading = false;
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
   // }

  }

  personSubmittedHandler($event: any) {
    console.log(' Person  submitted '+ JSON.stringify($event));

    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.refreshNoDups();
    }

  }

  relationSubmittedHandler($event: any) {
    console.log(' Relation  submitted '+ JSON.stringify($event));
    this.operation='none';


    this.fromF$ = of();
    this.fromF$ = of(this.currentSource.person);
    
  }

  emailHandler($event: number) {
    console.log(' Attendee  emails '+ JSON.stringify($event));
    this.operation = 'none';
    const name = this.emailMap.get(this.theEmail);
    this.emailMap.set(this.theEmail, { first: name.first, last: name.last, count: [$event] });
    this.theEmailIndex = $event;

    this.disableBtn = false;
    this.refreshNoDups();
  }

  attendeeSubmittedHandler($event: any) {
    console.log(' Attendee  submitted '+ JSON.stringify($event));
    this.operation='none';
    this.emitterService.emitter.emit('Message',$event);
    this.disableBtn = false;
    if (this.user.preferences && this.user.preferences.autoRefresh) {
      this.refreshNoDups();;
    }
    
  }

  editPerson($event: any) {
    console.log(' Attendee  edit '+ JSON.stringify($event));
  }

checkAttendeeInActCamp(a: Person): Promise<boolean> {
  this.disableBtn = true;
  return new Promise ( async (resolve, reject) => {
      const added = await firstValueFrom(this.activeCampaignService.syncContact(a));
      this.disableBtn = false;
      resolve(true);

  })
}


 makeEventAttendee(attrs: EventAttendee[]) : Promise<EventAttendee[]> {
  return new Promise ( async (resolve, reject) => {
    this.disableBtn = true;
    //console.log('M E A '+ JSON.stringify(attrs));
        const e = attrs;
    this.hours = [];
    this.paid = [];
    for (let i = 0; i < e.length; i++) {
      if (!this.onlyShowToday || e[i].start_date == DateToYYMMDD()) {
          let person: Person;
          this.paid.push(e[i].paid ? e[i].paid : "0");
          this.attended.push(e[i].attended > 0);

          if (e[i].person_id && e[i].person_id != '') { // Does the attendee have an email?? are they in DB?
            person = await this.searchService.searchPersonKey(e[i].person_id, true); // might have an email NOT in DB
          }
          if (!person && e[i]._key && e[i]._key != '') { // Does the attendee have an email?? are they in DB?
            e[i].person_id = e[i]._key;
            person = await this.searchService.searchPersonKey(e[i]._key, true); // might have an email NOT in DB
          }
                    
          if (this.theEmail !== "" && e[i].email == this.theEmail) {
            if (i != this.theEmailIndex) { // Clear all except the expected one
              e[i].email = "";

              console.log(`cleared email - set in DB  ` + JSON.stringify(e[i]));
              if (this.event.source !== 'calendly') {  // WP Event
                const res = await firstValueFrom(this.eventDBService.updateEventAttendee(e[i]));
              } else {
                const res = await firstValueFrom(this.arangoService.setEventAttendee(e[i])); // does update
              }
            }
          }
          if (!person && e[i].email) { // Does the attendee have an email?? are they in DB?
            const persons = await firstValueFrom(this.searchService.searchEmail(e[i].email, true));
            if (persons && persons.length > 0) {
              person = persons[0];
              person.userUID = person._key;
            } else {
              person = await this.searchService.searchPersonKey(e[i]._key, true); // might have an email NOT in DB
            }
          }
          
          e[i].newPerson = personFromAttendee(e[i]);
          if (!e[i].nn) {
            e[i].nn = NaturalNumber.None;
          }
          e[i].tagged = false;
            
          e[i].event_id = 'EE' + this.event._key;
          if (person) {
            e[i].person = person;
            if (person && person.contact && person.contact.names && person.contact.names.name) {
              e[i].dbFirst = person.contact.names.name.first;
              e[i].dbLast = person.contact.names.name.last;
            } else {
              e[i].dbFirst = e[i].first;
              e[i].dbLast = e[i].last;
            }

            const roles: any = await firstValueFrom(this.arangoService.getRoles(person));
            if (roles && roles.content && roles.content.length > 0) {
              e[i].guild = levelFromSysRole(roles.content);
            }

  
            e[i].person_id = person._key;
            e[i].DNC = !canContact(person.contact);

    

            console.log("Found mail for " + e[i].email);
            console.log("Found NN for " + JSON.stringify(e[i].person.naturalNumbers));

            if (!e[i].person.naturalNumbers.identified) { // Handle bad conversion
              e[i].person.naturalNumbers.identified = makeMTNaturalNumber();
            }
            e[i].nnIssue = false;

            const attendedActivity = await firstValueFrom(this.arangoService.getActivity(person._key, this.event._key, 'Event Attended'));
            if (e[i].attended) { // attended then hours known
              let spent = 0;
              if (attendedActivity.content && attendedActivity.content.length > 0) {
            
                for (const acti of attendedActivity.content) {
                  spent = +acti.activity.value; // JUST TAKE THE LATEST - ignore the others
                }
              }
              this.hours.push(spent);

            } else { // not yet attended - use the defaults
              if (this.event.registerHours) {
                this.hours.push(Math.max(this.event.registerHours.BO9.hours, this.event.registerHours.ICF.hours, this.event.registerHours.MT.hours));
              } else {
                this.hours.push(1);
              }

                
            }
            if (e[i].person.naturalNumbers.asserted && e[i].person.naturalNumbers.asserted.naturalNumber) {
              e[i].sourceType = NaturalNumberTypes.Asserted;
              e[i].ass = e[i].person.naturalNumbers.asserted.naturalNumber.toString();
              
              
              e[i].assBy = fixIDBy(e[i].person.naturalNumbers.asserted);
            } else {
              e[i].sourceType = NaturalNumberTypes.UnKnown;
            }
          
            if (!e[i].person.naturalNumbers || (e[i].person.naturalNumbers &&
              (e[i].person.naturalNumbers.identified.naturalNumber === NaturalNumber.None ||
                e[i].person.naturalNumbers.identified.naturalNumber === NaturalNumber.OldNone))) {
                  
            } else {
              e[i].sourceType = NaturalNumberTypes.Identified;
              e[i].idBy = fixIDBy(e[i].person.naturalNumbers.identified);

              e[i].nn = e[i].person.naturalNumbers.identified.naturalNumber.toString();
            }

            e[i].difference = getDifferences(e[i].person, e[i].newPerson);
            const pDiffs = differencesForSource('Events', e[i].person); // what is already known from before, if anything
            if (pDiffs.acceptedAlready && sameDifference(pDiffs.differences, e[i].difference)) { // has it already been accepted and the diffs haven't changed
              e[i].diffAccepted = true;
            } else {
              e[i].diffAccepted = false;
            }
          }

          if (e[i].email && e[i].email != "") {
            let ids = this.emailMap.get(e[i].email);
            if (ids) { // has email
              if (!(ids.first == e[i].first && ids.last == e[i].last)) {
                ids.count.push(i);
                this.emailMap.set(
                  e[i].email, {
                  first: e[i].first,
                  last: e[i].last,
                  count: ids.count
                });
                this.attendees.push(e[i]); // can push - different name and email from existing
              } // else same name, ignore smae name, same email do not add to attendees
      
            } else { // no email
              /*
              this.emailMap.set(
                  e[i].email, {
                  first: e[i].first,
                  last: e[i].last,
                  count: [i]
                }
              )
              */
              this.attendees.push(e[i]); // can push - new email
            }

              
          } else {
            this.attendees.push(e[i]); // can push no email
          }
            
        }

        }
        const orderede = this.attendees.sort((n1: EventAttendee, n2: EventAttendee) => {
          const split1 = n1.event_time.split(':');
          const split2 = n2.event_time.split(':');

          if (split1.length > 0 && split2.length > 0) {
            if (+split1[0] > +split2[0]) return 1;

            if (+split1[0] < +split2[0]) return -1;
            
            if (+split1[1] > +split2[1]) return 1;

            if (+split1[1] < +split2[1]) return -1;
          }
          return 0;
        });
    this.attendees = orderede;
        this.theEmail = "";
        this.theEmailIndex = 0; // Clear until next time
        resolve(orderede);
  })
}
}

