@use '../../../styles/BO9-theme-variables.scss' as *;

.BO9-sub-card {
  width: 60%;
}

.personal-form {
  min-width: 450px;
  max-width: 750px;
  width: 100%;
}

.full-width {
  width: 100%;
}

.instructions {
	.instructions_indent p{
	  margin-left: 10px !important;
	}
  }

.mat-form-field-infix {
  width: 250px !important;
}


.contact {
  display: flex;
  > div {
    margin: auto;
    padding: 18px 0 0 36px;
    background: url("/assets/images/website/slash-green.png") no-repeat 0 0;
    background-size: 67px 58px;
  }
  h2 {
    color: #000000;
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    margin: 0 0 30px;
  }
  p {
    color: $BO9-green;
    font-size: 24px;
  }
}

:host {
  display: block;
}



.profile-tabs{
	.contact-form-title, .nn-form-title {
		display:none;
	}
	.tab-header{
		display:flex;
		justify-content: space-between;
		align-items: center;
		border-right: 1px solid #ddd;
		border-left: 1px solid #ddd;
		h3{
			display:inline-block;
			font-size: 130%;
			margin: 10px;
		}
		button {
			line-height: 28px;
			margin-right: 10px;
			display: inline-block;
		}
	}
	.mat-tab-label {
		min-width: 120px;
		padding: 0 15px;
	}

	.mat-tab-list{
		background: #f6f6f6;
		border: 1px solid #ddd;
		border-bottom:none;
	}
	.frame-box{
		border: 1px solid #0ec535;
		display: block;
		padding: 12px;
		margin-bottom: 12px;
	}
	.mat-expansion-panel-header{
		font-size: 115%;
		.header-name{
			font-size: 70%;
			color: #666;
		}
		.empty-title{
			color: #999;
		}
	}
}
