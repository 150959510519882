/* Colors */
$BO9-drk-gray: #485054;
$BO9-lt-gray: #F2F2F2;
$BO9-gray-light: $BO9-lt-gray;
$BO9-gray: #BFBFBF;
$BO9-green: #80BF00;
$BO9-blue: #4A90E2;
$BO9-white: #FFFFFF;
$BO9-black: #000000;
$BO9-notice: #660033;

$BO9-secondary-gray: #666666;

$BO9-screen-height: 1000px;
/* Tints */
$BO9-tint-drk-slt: rgba(0,0,0,0.05);
$BO9-tint-drk-lt: rgba(0,0,0,0.2);
$BO9-tint-drk: rgba(0,0,0,0.5);
$BO9-tint-drk-hvy: rgba(0,0,0,0.85);
$BO9-tint-lght-lt: rgba(255,255,255,0.2);
$BO9-tint-lght: rgba(255,255,255,0.5);
$BO9-tint-producer: rgba(128,191,0,0.15);
$BO9-tint-buyer: rgba(74,144,226,0.15);

/* Sizes */
$BO9-rounding: 8px;
$BO9-toolbar: 50px;

/* Buttons */
$BO9-button-default: $BO9-drk-gray;

/* Misc */
$BO9-bg: $BO9-lt-gray;
$BO9-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15), 0 0 1px 0 rgba(0,0,0,0.17), 0 4px 8px 0 rgba(0,0,0,0.07);
$BO9-box-shadow-inset: inset 0 2px 2px 0 rgba(0,0,0,0.3), inset 0 0 1px 0 rgba(0,0,0,0.32), inset 0 4px 8px 0 rgba(0,0,0,0.14);
$BO9-box-shadow-sub: 0 2px 2px 0 rgba(0,0,0,0.05), 0 0 1px 0 rgba(0,0,0,0.25), 0 1px 1px 0 rgba(0,0,0,0.15);
::ng-deep.mat-form-field-appearance-outline .mat-form-field-infix
 {
  top: 0;
  padding: .6em 0 .6em 0;
  color: $BO9-drk-gray;
}

.cdk-overlay-pane{
	height: max-content;
  background-color: white;
  width: max-content !important;
}

$BO9-tab-background-1:#ffffeb;
$BO9-tab-color-1:#303000;
$BO9-tab-background-2: #ebdefd;
$BO9-tab-color-2:#150331;
$BO9-tab-background-3:#e4ffec;
$BO9-tab-color-3:#00290d;
$BO9-tab-background-4:#ffe6eb;
$BO9-tab-color-4:#2b0008;
$BO9-tab-background-5:#e6e7fb;
$BO9-tab-color-5:#080930;
$BO9-tab-background-6:#d9eeff;
$BO9-tab-color-6:#002340;
$BO9-tab-background-7:#fff3ec;
$BO9-tab-color-7:#311100;
$BO9-tab-background-8:#cac9c0;
$BO9-tab-color-8:#211b03;
$BO9-tab-background-9:#e5f4d9;
$BO9-tab-color-9:#192a0c;
$BO9-tab-background-10:#fefbe9;
$BO9-tab-color-10:#1c1801;
$BO9-tab-background-11:#e3f4f0;
$BO9-tab-color-11:#0d221d;
$BO9-tab-background-12:#ffdfe1;
$BO9-tab-color-12:#240002;
$BO9-tab-background-13:#f9e8f8;
$BO9-tab-color-13:#1f081e;
$BO9-tab-background-14:#f8f2ed;
$BO9-tab-color-14:#20150c;

::ng-deep .mat-checkbox-checked .mat-checkbox-background,
::ng-deep .mat-checkbox-indeterminate .mat-checkbox-background {
background-color: #437891 !important;


/* Colors */
$BO9-drk-gray: #485054;
$BO9-lt-gray: #F2F2F2;
$BO9-gray: #BFBFBF;
$BO9-green: #80BF00;
$BO9-blue: #4A90E2;
$BO9-white: #FFFFFF;
$BO9-black: #000000;
$BO9-secondary-gray: #666666;
$BO9-gray-light: #545454;
$BO9-notice: #660033;


/* Tints */
$BO9-tint-drk-slt: rgba(0,0,0,0.05);
$BO9-tint-drk-lt: rgba(0,0,0,0.2);
$BO9-tint-drk: rgba(0,0,0,0.5);
$BO9-tint-drk-hvy: rgba(0,0,0,0.85);
$BO9-tint-lght-lt: rgba(255,255,255,0.2);
$BO9-tint-lght: rgba(255,255,255,0.5);
$BO9-tint-producer: rgba(128,191,0,0.15);
$BO9-tint-buyer: rgba(74,144,226,0.15);

/* Sizes */
$BO9-rounding: 8px;
$BO9-toolbar: 52px;

/* Buttons */
$BO9-button-default: $BO9-drk-gray;

/* Misc */
$BO9-bg: $BO9-lt-gray;
$BO9-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15), 0 0 1px 0 rgba(0,0,0,0.17), 0 4px 8px 0 rgba(0,0,0,0.07);
$BO9-box-shadow-inset: inset 0 2px 2px 0 rgba(0,0,0,0.3), inset 0 0 1px 0 rgba(0,0,0,0.32), inset 0 4px 8px 0 rgba(0,0,0,0.14);
$BO9-box-shadow-sub: 0 2px 2px 0 rgba(0,0,0,0.05), 0 0 1px 0 rgba(0,0,0,0.25), 0 1px 1px 0 rgba(0,0,0,0.15);


}