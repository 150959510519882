.mat-mdc-form-field-appearance-outline {

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .ngx-mat-file-input--default-icon {
      width: 1em;
    }
  }
}

.mat-mdc-form-field:not(.mat-form-field-appearance-outline) {

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    .ngx-mat-file-input--default-icon {
      display: block;
      width: 1.5em;
      height: 1.5em;
    }

    .mat-icon-button .ngx-mat-file-input--default-icon {
      margin: auto;
    }
  }
}

.ngx-mat-file-input {
  display: flex;
  line-height: 18px;
  align-items: center;

  .input-file {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}