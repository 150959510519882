import { Component, OnDestroy, Input, OnChanges, Output, EventEmitter, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { canContact, mkMTContact } from '../../../../../bo9-shared/models/contact/contact.model';
import { validationMessages } from 'src/helpers/form.helper';
import { Event, EventAttendee, MTTransaction_Detail, Set_Transaction, attendeeUnique } from '../../../../../bo9-shared/models/event/event.model';

import { DateToYYMMDDhhmmss, OnlyTimePart, OnlyTimePartNumbers } from '../../../../../bo9-shared/helpers/time.helper';
import { EventDBService } from 'src/services/events/eventdb.service';

import { ArangoService } from 'src/services/arangoservice/arango.service';

import { MatDialog } from '@angular/material/dialog';

import { findInvalidControlsRecursive } from '../../../helpers/form.helper';

import { GenderTypes, Person, personFromAttendee } from '../../../../../bo9-shared/models/person/person.model';
import { Slot, Slots, updateSlots } from '../../../../../bo9-shared/models/slot/slot.model';



export interface AttendeeFormValues {
  email: string;
  first: string;
  last: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  gender: string;
  }

@Component({
    selector: 'simple-attendee-form',
    templateUrl: './simpleattendeeForm.component.html',
    styleUrls: ['./attendeeForm.component.scss'],
    standalone: false
})
  export class SimpleAttendeeFormComponent implements OnDestroy, OnChanges {

    name: string;
    form: FormGroup;
    user: Person;
    subscriptions: Subscription[] = [];
    validationMessages = validationMessages;
    event: Event
  selectedPerson: boolean;
  submitted: boolean;
  newAttendee: EventAttendee;
  slots: Slots[];


  disableBtn = false;
  genderTypes = GenderTypes;

    get value(): AttendeeFormValues {
      console.log('name get '+ JSON.stringify(this.form.value));
      return this.form.value;
    }
  
    set value(value: AttendeeFormValues) {
      console.log('name get '+ JSON.stringify(value));
      this.form.setValue(value);
    }

    get firstControl() {
        return this.form.controls.first;
    }

    get lastControl() {
        return this.form.controls.last;
    }
    get phoneControl() {
      return this.form.controls.phone;
    }
    get genderControl() {
      return this.form.controls.gender;
    }
    isAdmin() {
      return false

    }
    get emailControl() {
      return this.form.controls.email;
    }
    constructor(private formBuilder: FormBuilder, 

      private arangoService: ArangoService,

      private dialog: MatDialog,

      private eventDBService: EventDBService) {

    this.makeForm();
      this.selectedPerson = false;
      this.submitted = false;

      this.eventDBService.getCurrentEvents().subscribe((result: any) => {
        if (result && result.content) {
          this.event = result.content[0];
        }
      });



    }

    makeForm() {
      this.form = this.formBuilder.group({
        first: [' ', Validators.compose([Validators.required])],
        last: [' ', Validators.compose([Validators.required])],
        email: ['', Validators.compose([Validators.required])],
        city: ['', Validators.compose([])],
        state: ['', Validators.compose([])],
        phone: ['', Validators.compose([])],
        gender: ['', Validators.compose([])],
        zip: [' ', Validators.compose([])],
        country: ['USA', Validators.compose([])],
        _key: ['', Validators.compose([])],
        DNC: [false, Validators.compose([])],
        //paid: ["0.00", Validators.compose([])],
        tandc: [true, Validators.compose([Validators.required])]
      });
      
    }

    ngOnChanges() {
      console.log('OnChange ');
      this.form.reset();
    }

    selectDNC() {
      this.form.controls.DNC.setValue(!this.form.value.DNC);
      console.log('dnc'+ JSON.stringify(this.form.value.DNC));
    }
  

    selecttandc() {
      console.log('tandc '+ JSON.stringify(this.form.value.tandc));
      this.form.controls.tandc.setValue(!this.form.value.tandc);
      console.log('tandc '+ JSON.stringify(this.form.value.tandc));
    }
  
  reserveATime() {
    if (this.form.valid) {
      this.arangoService.getEmptySlots(this.event._key).subscribe((result: any) => {
        if (result && result.content) {
          this.slots = result.content;
          var orderedDate = this.slots.sort( (d1: Slots, d2: Slots) => {
            const date1 = new Date(d1.eventDate);
            const date2 = new Date(d2.eventDate);

            if (date1 > date2) return 1;
            if (date2 > date1) return -1;
            return 0;

          })
          this.slots = orderedDate;
          for (var slots of this.slots) {

              
            var orderedAppt = slots.appointments.sort((n1: Slot, n2: Slot) => {
              const split1 = n1.appointmentTime.split(':');
              const split2 = n2.appointmentTime.split(':');

              if (split1.length > 0 && split2.length > 0) {
                if (+split1[0] > +split2[0]) return 1;

                if (+split1[0] < +split2[0]) return -1;
                
                if (+split1[1] > +split2[1]) return 1;

                if (+split1[1] < +split2[1]) return -1;
              }
              return 0;
            });
            slots.appointments = orderedAppt;
          }
        }
      });
    } else {
      alert("Oops missed something " + JSON.stringify(findInvalidControlsRecursive(this.form)));
      return (null);
    }
  
    }
    
    reserveTime(day, time: string) {
      console.log(day, time);

      const et = this.submitForm(day, time);

  
    }
  
  submitNow() {
    const et = this.submitForm(null, null);
  }

   async submitForm(date, time: string){
     console.log('Submit attendee form ' + JSON.stringify(this.form.value));
     if (!this.form.value.tandc) {
       alert("We are unable to accept requests for Natural Number Identifcation without Accepting our Terms and Conditions");
       return (null);
     }

      if (this.form.valid) {
        this.disableBtn = true;
         this.newAttendee = {
          _key: this.form.value._key? this.form.value._key : '', // filled in on insert
          person_id: this.form.value._key? this.form.value._key : '', 
          contact: mkMTContact(),
          mailCampaignID: 0,
          event_id: this.event?._key,
          paid:  "0.00",
          transaction_details: Set_Transaction("Self Registered",this.form.value.amountPaid, MTTransaction_Detail()),
          attended: 0,
          email: this.form.value.email,
          first: this.form.value.first,
          last: this.form.value.last,
          street1: '',
          street2: '',
          city: this.form.value.city? this.form.value.city: '',
          state: this.form.value.state? this.form.value.state: '',
          phone: this.form.value.phone? this.form.value.phone:'',
          gender: this.form.value.gender,
          zip: this.form.value.zip? this.form.value.zip: '',
          country: this.form.value.country? this.form.value.country: 'USA',
          start_date: date? date: this.event.startDate,
          end_date: date? date: this.event.endDate,
          event_time: time? time: OnlyTimePartNumbers(new Date()),
          DNC: this.form.value.DNC? this.form.value.DNC: false,
          end_time: "00:00",
          date_created: DateToYYMMDDhhmmss()
        }

 
          
        const res = await firstValueFrom(this.eventDBService.setSimpleEventAttendee(this.newAttendee));
        const day = date ? date : this.event.startDate
        const t = time? time: OnlyTimePartNumbers(new Date())
          alert("Reserved for "+ day + " at " + t);
          this.submitted = true;
  
          //  this.form.reset();
          
  
        this.disableBtn = false;
        
        if (date && time && res.content && res.content.last_id) {
          const newSlots = updateSlots(date, time, res.content.last_id, this.slots);
          if (newSlots) {
            const slotres = await(firstValueFrom(this.arangoService.putSlots(newSlots)))
          }

        }
    
      
 
      } else {
        alert("Oops missed something " + JSON.stringify(findInvalidControlsRecursive(this.form)));
        return(null)
      }
    }

  
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }


    resetForm() {
      this.form.reset();
      this.disableBtn = false;
    }

    

  }